@font-face {
  font-family: regular;
  src: url("./fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: mid;
  src: url("./fonts/Montserrat-Medium.ttf");
}

@font-face {
  font-family: big;
  src: url("./fonts/Montserrat-Bold.ttf");
}

html{
  font-family: montserrat;
}
body{
  font-family: montserrat;
  height: 100%;
  margin: 0;
}

.navbar{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 60px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  background-color: white;
  z-index: 9999;
}


.button{
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: mid;
  font-size: 20px;
  border: 0;
  cursor: pointer;
}

.backBtn{
  background-color: black;
  color: white;
}

.logo{
  position: absolute;
  left: 50%;
  top:50%;
  transform: translate(-50%, -50%);
  width: 160px;
}

.loginForm{
  width: 1000px;
}

.logoutBtn{
  position: absolute;
  right: 0;
  background-color: #FF5454;
  color:white;
}


.bodyContent {
  /* display: grid; */
  overflow: auto;
  margin: 60px 20px 80px 20px;
  height: fit-content;
  width: calc(100vw - 40px);
  /* max-width: 800px; */
  overflow: auto;
  width: 100vw;
}



.purpleBox{
  background-color: #50145C;
  border-radius: 20px;
  height: 14vh;
  width: 40vw;
}

.homeGrid{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 20px;
  margin-left: auto;
  margin-right: auto;
}

.linkText{
  margin: 0;
  text-align: center;
  color: white;
  font-size: 22px;
  font-family: mid;
  vertical-align: middle;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-decoration: underline;
}

.footer {
  width: 100%;
  background-color: #E9E9E9;
  height: 60px;
  padding: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
}


.poweredText{
  display: inline-block;
  margin: 0;
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.yookrLogo{
  display: inline-block;
  width: 125px;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}


.justified{
  text-align: center;
}

.username{
  display: inline-block;
}

.password{
  display: inline-block;
  margin-left: 60px;
}

#member{
  margin-right: 10px;
  height: 20px;
  width: 20px;
}

.inline-block{
  display: inline-block;
}

.logoBig{
  margin-left: auto;
  margin-right: auto;
}

.loginText{
  margin-bottom: 40px;
  font-family: big;
  font-size: 30px;
}

.loginBtn{
  margin-left: auto;
  margin-right: auto;
  background-color: #8EA646;
  border: none;
  padding: 20px 20px;
  width: 30vw;
  margin-top: 60px;
  color: white;
  font-family: regular;
  font-size: 30px;
  border-radius: 10px;
}

.loginInput{
  height: 60px;
  width: 30vw;
  font-size: 26px;
  padding-left: 20px;
  padding-right: 20px;
}

.rememberMe{
  margin-top: 20px;
}

.memberText{
  font-size: 20px;
}

.greenColor{
  background-color: #8EA646;
}

.header{
  font-family: regular;
  font-size: 60px;
  text-align: center;
  margin-bottom: 60px;
}

#floors{
  height: 50px;
  width:30vw;
  border-radius: 5px;
  font-family: regular;
  font-size: 22px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 30px;
}

.subheader{
  font-size: 40px;
  margin-top: 50px;
  margin-left: 20px;
  display: inline-block;
  font-family: mid;
}

.purpleBtn{
  margin-top: 30px;
  background-color: #50145C;
  color: white;
  padding: 14px 20px;
  font-family: regular;
  font-size: 22px;
  border: none;
  border-radius: 5px;
  margin-left: 20px;
  cursor: pointer;
}

.blueBtn {
  background-color: #007ef5 ;
}

.extraGridBtn{
  margin: 0;
  margin-bottom: 0px;
  height: 80px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.roomGrid{
  text-align: center;
  font-size: 22px;
  font-family: regular;
  gap: 20px;
  margin-top: 60px;
  margin-left: 20px;
  margin-right: 20px;
  border: 2px solid #000;
  padding: 20px 20px;
}

.roomGridItem{
  background-color: #8EA646;
  color:white;
  padding: 40px 60px;
  border-radius: 10px;
  text-decoration: underline;
}

.roomInfo{
  border: 2px solid black;
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px;
}

.roomInfo>h2{
  margin-top: 0;
  margin-left:0;
}

.value{
  border: 1px solid #777;
  width: fit-content;
  height: fit-content;
  display: inline-block;
  margin-left: 20px;
  margin-top: 30px;
}

.purpleHeader{
  background-color: #50145C;
  text-align: center;
}

.valueTitle{
  color:white;
  padding: 10px 20px;
  width: fit-content;
  font-size: 22px;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  font-family: mid;

}

.valueBox{
  font-family: mid;
  font-size: 22px;
  text-align: center;
  padding: 0px 20px;
}

.infoFlex{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.leftValue{
  margin-left: 0;
}

.ipm{
  border: 2px solid black;
  margin-top: 30px;
  margin-right: 20px;
  padding: 20px;
  width: 30vw;
  display: inline-block;
}

.ipm>h2{
  margin-top: 0;
  margin-left:0;
}

.ipmFlex>.value{
  margin-left: 0;
}

.ipmFlex{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px 20px;
  justify-content: center;
}

.substrate{
  display: inline-block;
  border: 2px solid black;
  padding: 20px 20px;
}

.substrateFlex{
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.substrate>h2{
  margin-top: 0;
  margin-left:0;
}

.substrateFlex>.value{
  margin-left: 0;
  margin-top: 0;
}

.equipment{
  border: 2px solid black;
  padding: 20px;
  width: 30vw;
  display: inline-block;
  margin-left: 20px;
}

.equipment>h2{
  margin-top: 0;
  margin-left:0;
}

.equipmentFlex{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px 20px;
  justify-content: center;
}

.equipmentFlex>.value{
  margin-left: 0;
}

.lowerValues{
  text-align: center;
}

.roomGrid>a{
  text-decoration: none;
}








.block{
  display: block;
}

.marginTop10{
  margin-top: 10px;
}


.plotRoomInfo{
  border: 2px solid black;
  padding: 20px 20px;
  width: 40vw;
}

.plotRoomInfo>h2{
  margin-top: 0;
  margin-left: 0;
}

.plotRoomInfoFlex{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.plotRoomInfoFlex>.value{
  width: 100%;
  margin:0;
}

.plotValues{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
}

.plantOptionsBtn{
  background-color: #8EA646;
  color: white;
  font-family: mid;
  font-size: 25px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  text-decoration: underline;
  margin-left: auto;
  float: right;
}

.font30{
  font-size: 30px;
}

.maintenance{
  margin-top: 30px;
  width: calc(100vw - 40px);
}

.optionsGrid{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  align-items: center;
}

.optionsGridItem{
  flex: 0 0 40%;
  padding: 20px 20px;
  margin: 10px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.optionTitle{
  color: white;
  font-family: mid;
  font-size: 45px;
  text-align: center;
  text-decoration: underline;
  margin: 0;
}

.pmHeader{
  font-family: regular;
  font-size: 28px;
}

#maintenance1, #maintenance2{
  font-family: regular;
  font-size: 22px;
  padding: 7px 20px;
  width: 300px;
  border-radius: 5px;
}

.maintenanceOne{
  text-align: center;
}

.maintenanceTwo{
  display: inline-block;
  margin-left: 60px;
  text-align: center;
}

.maintenanceInput{
  margin-left: 30px;
  margin-top: 30px;
  font-size: 26px;
  padding: 7px 20px;
  width: 400px;
  border-radius: 5px;
  font-family: regular;
}

.maintenanceForm{
  /* margin-top: 60px; */
  font-family: regular;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  /* padding-bottom: 30px; */
  background-color: #c7c7c7;
}




.flexTextBox{
  height: 150px;
  margin-top: 0;
  resize: vertical;
}

.descFlex{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 25px;
}

#alarmLvl{
  width: 444px;
}


.width100{
  width: 100vw;
}

.maintenanceDropdowns{
  display: flex;
  align-items: center;
  justify-content: center;
}

.regular{
  font-family: regular;
}

#plants{
  font-family: regular;
  font-size: 25px;
  padding: 10px 20px;
  margin-left: 30px;
  width: 300px;
  border-radius: 5px;
}

.plantOptionsBtn2{
  float: none;
  display: block;
  margin-left: 20px;
  margin-top: 40px;
}

.gridFilterNames{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  text-align: center;
  font-size: 25px;
  margin-top: 40px;
  font-family: mid;
  margin-left: 7.5px;
  margin-right: 7.5px;
}

.gridFilterDropdowns{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30px;
  margin-left: 27.5px;
  margin-right: 27.5px;
}

.filterSelect{
  font-size: 25px;
  padding: 10px 20px;
  border-radius: 5px;
  font-family: regular;
}

.gridFilterBtns{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 30px;
}

.oddBtn{
  padding: 10px 10px;
  font-size: 22px;
  font-family: regular;
  color: white;
  background-color: #8EA646;
  border: none;
}

.evenBtn{
  padding: 10px 10px;
  font-size: 22px;
  font-family: regular;
  color: white;
  background-color: #017D4F;
  border: none;
}

.rounded{
  border-radius: 5px;
}

.filterResetBtns{
  display: flex;
  flex-direction: row;
  gap: 60px;
  margin-top: 60px;
  justify-content: center;
}

.plantData{
  /* display: flex; */
  flex-direction: row;
  gap: 60px;
  margin-top: 30px;
  justify-content: center;
}

.width250{
  width: 250px;
}

.plantData>div>div>p{
  font-size: 28px;
}

.plantTableDiv{
  margin-top: 60px;
  margin-left: 20px;
  margin-right: 20px;
}

th{
  background-color: #50145C;
  color: white;
  padding: 10px 20px;
  font-family: regular;
  font-size: 16px;
  width: fit-content;
}

td{
  padding: 10px 20px;
  text-align: center;
}

table, th, td{
  border: 1px solid #777;
  border-collapse: collapse;
}

.plantTableTitle{
  margin: 0;
}

.anomaly{
  padding: 10px 0;
}

.topInput{
  margin-top: 0;
}

.editSelect{
  width: 100%!important;
}

.roomSelectGrid{
  display: grid;
  gap: 30px;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 1fr);
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.gridFilter {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 10px;
}

.gridFilterNames {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-column: 1 / span 4;
}

.gridFilterDropdowns {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-column: 1 / span 4;
}

.filterLabel {
  width: 100%;
  text-align: center;
}

.filterSelectWrapper {
  display: flex;
  justify-content: center;
}

.filterSelect {
  width: 100%;
}
.modal-close {
  font-size: 1.5rem;
}
.closeButton {
  font-size: 1.5em;
  z-index: 9998;
  /* Add other styling properties here */
}





.Grid {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  margin-left: 20px;
  margin-right: 20px;
  width: 93%;
  border: #000;
}

.GridRow {
  display: flex;
  flex-direction: row;
  margin: auto;
}

.GridItem {
  background-color: #8EA646;
  color:white;
  border-radius: 10px;
  text-decoration: underline;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  aspect-ratio: 1 / 1; /* add this property to maintain a 1:1 aspect ratio */
  font-size: 20px;
  text-align: center;
  margin: 5px;
  word-wrap: anywhere;
  transition: background-color 0.3s;
}


.GridSelectColor {
  background-color: #174c7e;
}

.GridSelectingColor {
  background-color: #007ef5;
}

.GridSelectedColor {
  background-color: #07345e;
}

.nonActive {
  background-color: #cccccc;
  color: #666666;
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 6.5vh auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  height: 80vh;
}
.modal-body {
  height: 50vh;
  padding: 1em;
  overflow: scroll;
}


.close-btn {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  padding: 5px;
}

.close-btn:hover,
.close-btn:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.title {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 0;
  transform: translateX(-20px);
  margin-top: 30px;
  margin-right: 30px
}

.centered {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 2%);
}

.relativeCentered {
  position: relative;
  top: 50%;
  /* transform: translateY(-50%); */
}

.editFormDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.editForm {
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  max-width: 400px;
}

label {
  margin-bottom: 10px;
}

.editInput {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid black;
  width: calc(100% - 22px);
}

.maintenanceFormSubmit {
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.maintenanceFormSubmit:hover {
  background-color: #3e8e41;
}

.marginFooter{
  height: 100px;
  width: 100%;
}

.maintenaceGrid{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 1fr);
  gap: 20px;
  text-align: center;
}

.maintenaceGrid>.purpleBtn{
  font-size: 16px;
  padding: 10px 5px;
}

@media only screen and (min-width: 750px){
  .maintenaceGrid>.purpleBtn{
    font-size: 20px;
    padding: 20px 10px;
  }

  .maintenaceGrid{
    grid-template-columns: repeat(3, 1fr);
  }
}

.maintenanceFormElement{
  display: flex;
  align-items: center;
}

.centerFlex{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.longBtn{
  width: 400px;
}

.longBtnTopMargin{
  margin-top: 30px;
}

.mobileMargin{
  margin-top: 60vh;
}

.deleteBtn{
  background-color: rgb(240, 69, 69);
  display: inline-block;
  border:0;
  border-radius: 5px;
  padding: 10px 20px;
  color: white;
  font-family: regular;
  font-size: 16px;
}

.deleteBtnMargin {
  margin: 4px;
}

.gotoLinkWrapper {
  display: flex;
  justify-content: center;
}


.flexRow{
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.selectRoomCenter{
  transform: translate(-50%, 0%);
}

.gotoBtn{
  width: 55vw;
  text-align: center;
}

.leftAlign{
  justify-content: left;
  align-items:baseline;
}

.smallForm{
  width: 25%;
}

.inlineDeleteBtn {
  display: inline-block;
  margin-left: 20px;
}

.roomDeleteBtn{
  padding: 14px 20px;
  font-size: 22px;
  margin-top: 30px;
}

@media only screen and (min-width: 1050px){
  
}

@media only screen and (min-width: 900px) {
  .homeGrid{
    display: grid;
    width: 800px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .loginInput{
    width: 350px;
  }

  .roomSelectGrid{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 50px;
    width: 800px;
  }

  .mobileMargin{
    margin-top: 120px;
  }

  .maintenaceGrid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 30px;
  }

  .centered{
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .purpleBox{
    width: 100%;
    height: 20vh;
  }

  .linkText{
    font-size: 36px;
  }

  .selectRoomCenter{
    top: 50%;
    transform: translate(-50%, -49%);
  }
}

.signatureCanvas {
  border:rgba(0, 0, 0, 0.4) solid 3px;
}

.fontSizeSignatureGrid {
  font-size: 25px;
}

.PlantSelectActions {
  overflow: hidden;
  transition: max-height 400ms;
  max-height: 20vh;
}

.PlantSelectActionsHidden {
  max-height: 0;
  transition: max-height 400ms;
}